import * as React from "react"
import { CheckIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import LogoLinode from "../../images/logo-linode-white.svg"

const tiers = [
  {
    name: 'DevOps',
    href: 'https://buy.stripe.com/6oE8za0YafxD0AE14d',
    priceMonthly: 12250,
    frequency: 'consult',
    description: 'Your Linode automation strategy.',
    features: [
      'Built on open source foundations',
      'Caters for all languages and backends',
      'Customised Terraform scripts and Github actions',
      'Advice around branch and tag based workflow',
      'In person, virtual or hybrid'
    ],
  },
  {
    name: 'Architecture',
    href: 'https://buy.stripe.com/00geXy9uGbhncjmeV2',
    priceMonthly: 1750,
    frequency: 'day',
    description: '10 day design sprint to establish a game plan.',
    features: [
      'Detailed design of a scalable application',
      'Fully provider independent architecture',
      'Purely based on open source technologies',
      'Best practices around software engineering',
      'Best practices around Linode infrastructure',
      'Advice on CD/CD pipelines',
      'Advice on backends, and transport technologies',
      'In person, virtual or hybrid'
    ],
  },
];

const LinodeCapabilityPage = () => {
  return (
    <Layout bgClass="bg-linode-light" fgMode="light">
        <Seo 
          title="Build on Linode"
          description="Lease our knowledge to build on Linode"
        />
        <div className="text-white bg-linode-light">
          <div className="mx-4 mb-10 lg:max-w-screen-lg lg:mx-auto "> 
            <section className="flex flex-col items-center justify-between md:flex-row">
              <h1 className="mb-10 text-3xl font-semibold md:mb-0">Build on Linode</h1>
              <LogoLinode/>
            </section>
          </div>
          <div className="pt-4 md:pt-12 sm:pt-16 lg:pt-24">
            <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                <h2 className="text-lg font-semibold leading-6 tracking-wider text-green-100 uppercase">Pricing</h2>
                <p className="text-3xl font-bold text-white sm:text-4xl lg:text-5xl">
                  Foundational advice to build on Linode
                </p>
                <p className="text-xl text-green-100">
                  Fixed price engagements to help you harness Linode's offerings.
                </p>
              </div>
            </div>
          </div>
        <div className="pb-12 mt-8 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 bg-linode-light h-3/4" />
          <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {tiers.map((tier) => (
                <div key={tier.name} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide text-green-700 uppercase bg-green-100 rounded-full"
                        id="tier-standard"
                      >
                        {tier.name}
                      </h3>
                    </div>
                    <div className="flex items-baseline mt-4 text-6xl font-extrabold text-black">
                      ${tier.priceMonthly}
                      <span className="ml-1 text-2xl font-medium text-gray-500">/{tier.frequency}</span>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                  </div>
                  <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-gray-50 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon className="w-6 h-6 text-green-500" aria-hidden="true" />
                          </div>
                          <p className="ml-3 text-base text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                    <div className="rounded-md shadow">
                      <a
                        href={tier.href}
                        className="flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-gray-800 border border-transparent rounded-md hover:bg-gray-900"
                        aria-describedby="tier-standard"
                      >
                        Secure your spot
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative px-4 mx-auto mt-4 max-w-7xl sm:px-6 lg:px-8 lg:mt-5">
          <div className="max-w-md mx-auto lg:max-w-5xl">
            <div className="px-6 py-8 bg-gray-100 rounded-lg sm:p-10 lg:flex lg:items-center">
              <div className="flex-1">
                <div>
                  <h3 className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide text-gray-800 uppercase bg-white rounded-full">
                    Build it for us
                  </h3>
                </div>
                <div className="mt-4 text-lg text-gray-600">
                  We can design, build and host your custom applications. Our approach is fit for purpose. We do not rely on commissions
                  and kickbacks from providers. <span className="font-semibold text-gray-900">We're engineers building beautiful applications that scale</span>.
                </div>
              </div>
              <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                <a
                  href="mailto:linode@anomaly.ltd"
                  className="flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                >
                  Enquire
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center pt-12 mx-4 text-center md:mx-auto">
        <p className="mb-10 text-sm text-gray-600">
            All prices are in  Australia dollars and are <a href="https://www.ato.gov.au/business/gst/">GST</a> inclusive
          </p>
          <p className="mb-2 text-xl text-gray-700">Learn about our work and partnership with Stripe</p>
          <a 
          className="flex items-center text-linode-dark " 
          href="https://www.linode.com/linode-for-digital-agencies">
            Digital Agencies that trust Linode<ArrowRightIcon className="h-4 ml-2"/>
          </a>
        </div>
      </div>
      </div>
    </Layout>
 );
};
export default LinodeCapabilityPage
